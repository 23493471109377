<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="店铺关系修改"
    ></el-page-header>
    <div class="select-container">
      <el-form
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="店铺标识ID">
          <el-input
            style="width: 40%"
            v-model="info.credit_extensio_storeFlag"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input
            style="width: 40%"
            v-model="info.credit_extension_store"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="授信额度">
          <el-input style="width: 30%" v-model="info.money"></el-input>
        </el-form-item>
        <el-form-item label="授信余额">
          <el-input style="width: 30%" v-model="info.limit_money" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" style="width: 30%" v-model="info.remark"></el-input>
        </el-form-item>
        <el-form-item label="最后修改时间">
          <el-input style="width: 30%" v-model="info.fix_time" disabled></el-input>
        </el-form-item>
        <!-- <el-form-item label="最后修改时间">
          <el-input style="width: 30%" v-model="info.fix_time" disabled></el-input>
        </el-form-item> -->
        <div class="line"></div>
        <h3>店铺授信改变记录</h3>
        <el-table
          :data="record"
          style="width: 100%"
          height="200"
          :cell-style="csllStyle"
        >
          <el-table-column fixed prop="storeName" label="店铺" width="260">
          </el-table-column>
          <el-table-column prop="oldMoney" label="原授信额度" width="200">
          </el-table-column>
          <el-table-column prop="oldLimit" label="原授信余额" width="200">
          </el-table-column>
          <el-table-column prop="fixMoney" label="更改后授信额度" width="200">
          </el-table-column>
          <el-table-column prop="fixLimitMoney" label="更改后授信余额" width="200">
          </el-table-column>
          <el-table-column prop="fixTime" label="更改时间" width="260">
          </el-table-column>
        </el-table>
        <el-form-item class="button-bottom">
          <el-button type="primary" @click="onSubmit">保存修改</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import moment from "moment";
import secret from "@/utils/secret.js";
import Big from "big.js";
export default {
  inject: ["reload"],
  data() {
    let self = this;
    let NoEvent = function () {
      self.verify();
    };
    return {
      info: "",
      record: [],
    };
  },
  created() {
    this.info = JSON.parse(secret.Decrypt(this.$route.params.id));
    console.log('this.info:',this.info);
    this.record = JSON.parse(this.info.record)
    this.info.fix_time = moment().format("YYYY-MM-DD HH:mm:ss");
  },
  mounted() {
    const userKey = this.info.storeFlag;
    let userInfo = true;
    axios
      .post(this.GLOBAL.api + "/store", { userKey, userInfo })
      .then((res) => {
        this.tableData = res.data.val;
      });
    this.record.push({
        storeName: this.info.credit_extension_store,
        oldMoney: this.info.money,
        oldLimit: this.info.limit_money,
        fixTime: this.info.fix_time,
        fixMoney: 0,
        fixLimitMoney: 0
    })
  },
  watch: {
    'info.money':{
      deep: true,
      handler:function(res) {
          this.info.limit_money = +Big(res).minus(+this.record[this.record.length-1].oldMoney).plus(+this.record[this.record.length-1].oldMoney)
          this.record[this.record.length-1].fixMoney = this.info.money;
          this.record[this.record.length-1].fixLimitMoney = this.info.limit_money;
      }
    },
  },
  methods: {
    goBack() {
      this.$router.push("/store/list");
    },
    onSubmit() {
        this.info.record = JSON.stringify(this.record);
      const updateCredit = true;
      const myStoreKey = secret.Decrypt(localStorage.getItem("user_key"));
      const fixExtension = this.info
      axios.post(this.GLOBAL.api + '/store', {updateCredit, myStoreKey, fixExtension}).then(res => {
          console.log(res);
        if(res.status === 200 && res.data.statusCode === 200) {
          this.$message({
            type: 'success',
            message: '修改成功！'
          })
        } else {
          this.$message({
            type: 'error',
            message: '修改失败请重试！'
          })
        }
      })
    },
    onCancel() {
      this.$confirm("您还未保存修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/store/extension");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    csllStyle(row) {
      // // 监听tabel数据进行操作，如颜色警告
      if (row.row.verify === "1") {
        return (row.row.verify = "超级管理员");
        // row.column.label = '正常'
      } else if (row.row.verify === "2") {
        return (row.row.verify = "普通管理员");
        // row.column.label = '正常'
      }
      if (row.row.adminState === "1") {
        return (row.row.adminState = "正常");
        // row.column.label = '正常'
      } else if (row.row.adminState === "2") {
        return (row.row.adminState = "冻结/临时停用");
        
        // row.column.label = '正常'
      }
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 1% 3%;
  position: relative;
}
.box-card {
  width: 100%;
  height: 100%;
}
.iconStyle {
  color: green;
  font-weight: bold;
  position: absolute;
  top: 2%;
  left: 40%;
}
.line {
  border: 1px dashed;
  margin-bottom: 10px;
}
.button-bottom {
  margin-top: 20px;
}
</style>